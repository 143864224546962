var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-list"},[_c('card-header-body',{ref:"header",attrs:{"node":_vm.headerTitle}}),_c('div',{staticClass:"content-scroll",attrs:{"id":"content-scroll"}},[_c('ModalLayout',{ref:"orderDisabled"},[_c('p',{staticClass:"order-disabled-text f-w3"},[_vm._v(" ランクが違うため選択できません。Superオファーの編集を行うか、他のSuperオファーを選択、新規作成おねがいします ")])]),_c('ModalLayout',{ref:"order-edit"},[_c('p',{staticClass:"order-edit-text"},[_vm._v("こちらのSuperオファーを編集しますか？")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn-edit",on:{"click":function($event){$event.preventDefault();return _vm.editOrder.apply(null, arguments)}}},[_vm._v("はい")]),_c('button',{staticClass:"btn-cancel",on:{"click":_vm.cancelEditOrder}},[_vm._v("いいえ")])])]),_c('ModalLayout',{ref:"notExistOrderForCast"},[(_vm.cast)?_c('p',{staticClass:"order-disabled-text f-w3"},[_vm._v(" "+_vm._s(_vm.cast.user.nickname)+"さんのレベルに該当しているSuperオファーを出してください ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-wrap order-list__list",attrs:{"id":"order-list"}},_vm._l((_vm.orderList),function(order,index){return _c('div',{key:index,staticClass:"order-list__item"},[_c('div',{class:{
            'order-list__item--disabled__none': !_vm.profileId,
            'order-list__item--confirm':
              order.status_id == _vm.ADS_STATUS_VALUE.CONFIRMED,
            'order-list__item--disabled': !(
              _vm.profileId &&
              _vm.cast &&
              !(
                order.status !== _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.OPEN] ||
                order.number_of_applicants === order.female_participants ||
                _vm.checkDesignation(order)
              ) &&
              order.female_golf_ranks.includes(_vm.cast.user.female_golf_rank) &&
              order.female_prego_ranks.includes(_vm.cast.user.female_prego_rank)
            )
          }},[_c('div',{staticClass:"order-list__status d-flex",on:{"click":function($event){return _vm.showModal(order)}}},[(
                _vm.profileId &&
                  _vm.cast &&
                  !(
                    order.status !== _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.OPEN] ||
                    order.number_of_applicants ===
                      order.female_participants ||
                    _vm.checkDesignation(order)
                  ) &&
                  order.female_golf_ranks.includes(
                    _vm.cast.user.female_golf_rank
                  ) &&
                  order.female_prego_ranks.includes(
                    _vm.cast.user.female_prego_rank
                  )
              )?_c('AppCheckbox',{attrs:{"title":order.status},on:{"click-checkbox":function($event){order.isConfirm = $event}},model:{value:(order.isConfirm),callback:function ($$v) {_vm.$set(order, "isConfirm", $$v)},expression:"order.isConfirm"}}):_c('div',{staticClass:"span-status f-w3",class:{
                'span-status-red':
                  order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL,
                'span-status-gray':
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON ||
                  order.status_id == _vm.ADS_STATUS_VALUE.ASSEMBLED ||
                  order.status_id == _vm.ADS_STATUS_VALUE.MANUAL_TERMINATION ||
                  order.status_id == _vm.ADS_STATUS_VALUE.AUTO_TERMINATION,
                'span-status-blue':
                  order.status_id == _vm.ADS_STATUS_VALUE.CONFIRMED
              },on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',{staticClass:"dot",class:{
                  'dot-red': order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL,
                  'dot-gray':
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON ||
                    order.status_id == _vm.ADS_STATUS_VALUE.ASSEMBLED ||
                    order.status_id == _vm.ADS_STATUS_VALUE.MANUAL_TERMINATION ||
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_TERMINATION,
                  'dot-blue': order.status_id == _vm.ADS_STATUS_VALUE.CONFIRMED
                }}),(order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL)?_c('span',[_vm._v(" オートキャンセル ")]):(order.status_id == _vm.ADS_STATUS_VALUE.CONFIRMED)?_c('span',[_vm._v(" マッチング成立 ")]):(
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM
                )?_c('span',[_vm._v(" キャンセル ")]):_c('span',[_vm._v(" "+_vm._s(order.status)+" ")])]),_c('div',{staticClass:"d-flex"},[(order.designation.length > 0)?_c('div',{staticClass:"design-order f-w3"},[_vm._v(" 個人オファー ")]):_vm._e(),(
                  !_vm.profileId &&
                    order.isExpand === true &&
                    order.status === _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.OPEN] &&
                    order.number_of_applicants === 0
                )?_c('img',{staticClass:"ml-3",attrs:{"src":require('@/assets/image/edit.svg'),"alt":"edit"},on:{"click":function($event){return _vm.editOrderModal(order.ad_id, order.designation.length)}}}):_vm._e()])],1),(!order.isExpand)?[_c('div',{staticClass:"order-list__recap order-list__item__target recap_time",attrs:{"id":"recap_time"},on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('p',{staticClass:"w-100 p-top f-w3"},[_vm._v(" ラウンド日時/お客様人数／ピックアップエリア ")]),_c('p',{staticClass:"w-100 p-bottom f-w6",class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(_vm.$dayjs(order.scheduled_meeting_datetime).format("M月D日 H"))+"時頃／"+_vm._s(order.male_participants)+"人"),(order.golf_area)?_c('span',[_vm._v("／")]):_vm._e(),_vm._v(_vm._s(order.golf_area)+" ")])]),_c('div',{staticClass:"\n                order-list__recap order-list__item__target\n                recap_candidate\n              ",class:{
                'recap_candidate-golf': order.new_apply > 0,
                gray:
                  order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
              },attrs:{"id":"recap_candidate"},on:{"click":function($event){_vm.$router.push({
                  name: 'OrderCast',
                  query: {
                    adId: order.ad_id,
                    adStatus: order.status_id,
                    maxCast: order.female_participants,
                    isExpand_order: order.isExpand,
                    designation_length: order.designation.length,
                    isDisabled:
                      order.status !== _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.OPEN],
                    total: _vm.numberWithCommas(
                      Number(order.assumed_settlement_points) +
                        Number(order.assumed_transportation_costs)
                    )
                  }
                })}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"w-100 p-top f-w3"},[_vm._v("応募中／オファー人数")]),_c('div',{staticClass:"w-100 p-bottom f-w6 d-flex align-items-center"},[(
                        order.number_of_applicants >=
                          order.female_participants
                      )?_c('img',{attrs:{"src":require("@/assets/image/order_icons/golf.svg"),"alt":"Flame"}}):_vm._e(),_c('p',{staticClass:"mb-0",class:{
                        'text-green':
                          order.number_of_applicants >=
                          order.female_participants,
                        gray:
                          order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                          order.status_id ==
                            _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                          order.status_id ==
                            _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                          order.status_id ==
                            _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                      }},[_vm._v(" "+_vm._s(order.number_of_applicants)+"人 ")]),_vm._v(" ／"+_vm._s(order.female_participants)+"人 ")])]),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)])]:_vm._e(),(order.isExpand)?[_c('div',{staticClass:"w-100 order-list__item__target",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('div',{staticClass:"text-left text-long fs-14 f-w3"},[_vm._v(" ラウンド日時/お客様人数 ")]),_c('div',{staticClass:"text-right f-w3",class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(_vm.$dayjs(order.scheduled_meeting_datetime).format("M月D日 H"))+"時頃／"+_vm._s(order.male_participants)+"人 ")])]),_c('div',{staticClass:"\n                order-list__item__target\n                d-flex\n                flex-wrap\n                align-items-center\n                justify-content-between\n              ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',{staticClass:"fs-14 f-w3"},[_vm._v(" ピックアップエリア ")]),_c('span',{staticClass:"fs-14 f-w3",class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(order.golf_area)+" ")])]),_c('div',{staticClass:"\n                order-list__item__target\n                d-flex\n                flex-wrap\n                align-items-center\n                justify-content-between\n              ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',{staticClass:"keep-word fs-14 f-w3"},[_vm._v(" プラン ")]),_c('span',{staticClass:"fs-14 f-w6",class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(order.plan)+" ")])]),_c('div',{staticClass:"\n                order-list__item__target\n                d-flex\n                flex-wrap\n                align-items-center\n                justify-content-between\n              ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',{staticClass:"f-w3 fs-14"},[_vm._v("女性オファー人数")]),_c('span',{staticClass:"f-w6 fs-14",class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(order.female_participants)+"人 ")])]),_c('div',{staticClass:"\n                order-list__item__target\n                d-flex\n                flex-wrap\n                align-items-center\n                justify-content-between\n              ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',{staticClass:"fs-14 f-w3"},[_vm._v(" ゴルフ場 ")]),_c('span',{class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(order.golf_course)+" ")])]),(order.designation.length == 0)?[_c('div',{staticClass:"\n                  order-list__item__target\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" ゴルフレベル ")]),_c('span',{class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(" "+_vm._s(_vm.getName("female_golf_ranks", index))+" ")])]),_c('div',{staticClass:"\n                  order-list__item__target\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" ランク ")]),_c('span',{class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(" "+_vm._s(_vm.getPregoRank("female_prego_ranks", index))+" ")])])]:_vm._e(),_c('div',{staticClass:"\n                order-list__item__target\n                d-flex\n                flex-wrap\n                align-items-center\n                justify-content-between\n              ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" ゴルフシチュエーション ")]),_c('span',{class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(_vm.getName("attitudes", index))+" ")])]),(order.designation.length == 0)?[_c('div',{staticClass:"\n                  order-list__item__target\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" 年齢 ")]),_c('span',{class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(" "+_vm._s(_vm.getName("preferred_ages", index))+" ")])]),_c('div',{staticClass:"\n                  order-list__item__target\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" タイプ ")]),_c('span',[_vm._v(" "+_vm._s(_vm.getName("appearances", index))+" ")])]),_c('div',{staticClass:"\n                  order-list__item__target\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" スタイル ")]),_c('span',{class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(" "+_vm._s(_vm.getName("figures", index))+" ")])]),_c('div',{staticClass:"\n                  order-list__item__target\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                ",on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('span',[_vm._v(" その他 ")]),_c('span',{class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(" "+_vm._s(_vm.getName("other_preferences", index))+" ")])])]:_vm._e(),_c('div',{staticClass:"order-list__item__target",attrs:{"id":"candidate"},on:{"click":function($event){_vm.$router.push({
                  name: 'OrderCast',
                  query: {
                    adId: order.ad_id,
                    adStatus: order.status_id,
                    maxCast: order.female_participants,
                    isDisabled:
                      order.status !== _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.OPEN],
                    isExpand_order: order.isExpand,
                    designation_length: order.designation.length,
                    total: _vm.numberWithCommas(
                      Number(order.assumed_settlement_points) +
                        Number(order.assumed_transportation_costs)
                    )
                  }
                })}}},[_c('div',{staticClass:"\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  justify-content-between\n                "},[_c('span',{staticClass:"fs-14 f-w3 gray"},[_vm._v("オファー状況")]),_c('p',{staticClass:"f-w6 fs-14 mb-0 d-flex align-items-center"},[_c('span',{staticClass:"fs-14 f-w6 value golf-member",class:{
                      gray:
                        order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }},[_c('span',{class:{
                        'text-golf':
                          order.number_of_applicants >=
                          order.female_participants,
                        gray:
                          order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                          order.status_id ==
                            _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                          order.status_id ==
                            _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                          order.status_id ==
                            _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                      }},[_vm._v(_vm._s(order.number_of_applicants))]),_vm._v("／"+_vm._s(order.female_participants))]),_c('img',{class:{
                      'img-gray':
                        order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    },attrs:{"src":require('@/assets/image/right-arrow.svg'),"alt":"see all candidate"}})])])]),(order.request)?_c('div',{staticClass:"order-list__item__target",attrs:{"id":"note"},on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('p',{staticClass:"fs-14 f-w3 mb-0 request__text",class:{
                  gray:
                    order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id ==
                      _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }},[_vm._v(" "+_vm._s(order.request)+" ")])]):_vm._e(),_c('b-row',{staticClass:"w-100 order-list__item__target",class:{
                gray:
                  order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                  order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
              },attrs:{"id":"price"},on:{"click":function($event){$event.preventDefault();return _vm.showModal(order)}}},[_c('div',{staticClass:"\n                  w-100\n                  d-flex\n                  align-items-center\n                  justify-content-between\n                "},[_c('span',{staticClass:"f-w6",class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v("合計")]),(order.designation.length > 0)?_c('span',{staticClass:"f-w6",class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(_vm._s(_vm.numberWithCommas( Number(order.assumed_settlement_points) + Number(order.assumed_transportation_costs) + Number(order.designation_fee) ))+"P")]):_c('span',{staticClass:"f-w6",class:{
                    gray:
                      order.status_id == _vm.ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        _vm.ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == _vm.ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }},[_vm._v(_vm._s(_vm.numberWithCommas( Number(order.assumed_settlement_points) + Number(order.assumed_transportation_costs) ))+"P")])])])]:_vm._e(),_c('div',{staticClass:"\n              d-flex\n              justify-content-center\n              order-list__item__target order-list__expand\n            ",class:{
              'item-golf': order.new_apply > 0 && !order.isExpand
            }},[(!order.isExpand)?_c('b-button',{staticClass:"btn-expand f-w3",on:{"click":function($event){return _vm.changeExpand(index)}}},[_vm._v(" 詳細 "),_c('b-icon',{attrs:{"icon":"chevron-down"}})],1):_c('b-button',{staticClass:"btn-expand",on:{"click":function($event){return _vm.changeExpand(index)}}},[_vm._v(" 閉じる "),_c('b-icon',{attrs:{"icon":"chevron-up"}})],1)],1),(
              order.isExpand === true &&
                (order.status === _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.OPEN] ||
                  order.status ===
                    _vm.ADS_STATUS_TEXT[_vm.ADS_STATUS_VALUE.CONFIRMED])
            )?_c('div',{staticClass:"\n              d-flex\n              justify-content-center\n              w-100\n              d-cancel\n              order-list__item__target\n            "},[_c('button',{staticClass:"btn-cancel cancel-order f-w3",on:{"click":function($event){return _vm.openModalCancelOrderMatching(
                  order.ad_id,
                  order.status_id,
                  order.scheduled_meeting_datetime
                )}}},[_vm._v(" オファーをキャンセル ")])]):_vm._e()],2)])}),0),(!_vm.orderList.length && _vm.disable === true)?_c('div',{staticClass:"w-100 f-w3 no-data"},[_vm._v(" 現在オーダーはありません。 ")]):_vm._e(),(_vm.profileId)?_c('div',{staticClass:"btn-submit2",class:{ disabled: !_vm.allowSubmit }},[_c('button',{staticClass:"f-w6",attrs:{"disabled":!_vm.allowSubmit},on:{"click":function($event){return _vm.designateOrder()}}},[(_vm.allowSubmit)?_c('img',{attrs:{"src":require('@/assets/image/document2.svg'),"alt":"document"}}):_c('img',{attrs:{"src":require('@/assets/image/document3.svg'),"alt":"document"}}),_vm._v(" オファーする ")])]):_vm._e(),_c('b-modal',{ref:"confirm-cancel__popup",attrs:{"id":"confirm-cancel__popup","hide-footer":"","centered":""}},[_c('div',{staticClass:"text-notify d-flex f-w3 justify-content-center text-center"},[_vm._v(" "+_vm._s(_vm.textCancel)+" ")]),_c('div',{staticClass:"d-flex d-button"},[_c('button',{staticClass:"btn btn-ok f-w3",on:{"click":_vm.cancelOrder}},[_vm._v("はい")]),_c('button',{staticClass:"btn btn-cancel f-w3",on:{"click":function($event){return _vm.$refs['confirm-cancel__popup'].hide()}}},[_vm._v(" いいえ ")])])]),_c('scroll-loader',{attrs:{"loader-method":_vm.getListOrder,"loader-disable":_vm.disable}})],1),_c('CardFooter-body',{ref:"footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }